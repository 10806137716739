
import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";

import DisplayFiles from "@/views/DisplayDocument.vue";

import { BASE_API_URL } from "../../../config";

import { VueEditor } from "vue2-editor";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";

@Component({ components: { VueEditor, DisplayFiles } })
export default class ScenarioDeskComponent extends Vue {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public lenderDetails: any = [];
  public subject = null;
  public data = [];
  public messageFromTextBox = null;
  public lenderEmailId = null;
  public isEmailHasNoBody = false;
  public inProcess = false;
  public type = "reply";
  public replyType = null;
  public lenderName = null;
  public scenerioHistoryData = null;
  public responseData = [];
  public isLoanDetailsChecked = true;
  public file: any = [];
  public fileToDisplay = null;
  public currentFileIndex = 0;
  public showNextButton = false;
  public showPreviousButton = false;
  public fileType = null;
  public dotLoader: any = null;
  public showMessageLoader = false;
  public attachLoader = false;

  public customToolBar = [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }]
  ];

  public async getBorrowerDetails() {
    this.showMessageLoader = true;
    let response = await Axios.post(
      //to get the all the information of borrower because we are sending the borrower informatio to lender
      BASE_API_URL + "broker/getBorrowerListforScenarioDeskMessageList",
      {
        id: this.$route.query.id
      });

    this.lenderDetails = response.data.borrowers[0];
    await this.getMessage();
  }

  get userName() {
    return (
      this.$store.state.sessionObject.userInfo.firstName +
      " " +
      this.$store.state.sessionObject.userInfo.lastName
    );
  }

  public getMessage() {
    //to get the message,_id and date
    // this.lenderDetails.selectedLenders = JSON.parse(
    //   JSON.stringify(this.lenderDetails.selectedLenders)
    // );

    this.lenderDetails.selectedLenders.forEach(e => {
      this.lenderEmailId = e.email;
      this.lenderName = e.lenderName;
      this.subject = e.subject;

      if (e.lenderId == this.$route.query.lenderId) {
        e.conversation.forEach(element => {
          if (element.type == "response") {
            element["replyType"] = this.lenderName;
          } else {
            element["replyType"] = this.userName;
          }
        });
        this.data = e.conversation.reverse();
        this.responseData = e.conversation.filter(c => c.type == "response");
        this.showMessageLoader = false;
      }
    });
  }

  public replyToLender() {
    try {
      if (this.data.length > 0 && this.data[0].type == "response") {
        this.toggleReplyToBorrowerPopup();
      } else {
        this.$modal.show("sendNoReplyMessageToLender");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public showAttachment(path) {
    try {
      this.$refs.lc["setInitialValuesForShowingSamplesWithHeading"](null, [
        path
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  public toggleReplyToBorrowerPopup() {
    try {
      this.file = [];
      let nav = document.getElementById("reply-to-borrower");
      nav.classList.add("d-block");
      if (nav.style.display == "none") {
        nav.style.display = "block";
        nav.classList.remove("d-block");
      } else {
        nav.style.display = "none";
      }
    } catch (error) {
      console.log(error);
    }
  }
  public sendNoReplyMessageToLender(type) {
    if (type == "yes") {
      this.toggleReplyToBorrowerPopup();
      this.$modal.hide("sendNoReplyMessageToLender");
    } else {
      this.$modal.hide("sendNoReplyMessageToLender");
    }
  }

  public toSeeAttachment() {
    try {
      this.$refs.lc["setInitialValuesForLocalFiles"](this.file, null, null);
    } catch (error) {
      console.log(error);
    }
  }

  public async viewMessageBox() {
    this.$modal.show("viewMessageBox");
  }
  // public downloadFile(file) {
  //   window.open(file);
  // }
  public storedFile(event) {
    if (event.target.files.length == 0) {
      return;
    }
    this.file = [];
    if (event.target.files) {
      Object.values(event.target.files).forEach(element => {
        this.file.push(element);
      });
    } else {
    }
  }
  public hideModal() {
    this.$modal.hide("toSeeAttachment");
  }

  public async sceneriohistory(data1) {
    // this.$modal.hide("replytoborrower");
    if (data1.type == "response") {
      this.scenerioHistoryData = data1.message;

      this.$modal.show("sceneriohistory");
    }
  }

  public async sendEmail() {
    let formData = new FormData();
    this.file.forEach((element: any) => {
      formData.append("file", element);
    });
    this.inProcess = true;
    this.$store.state.wemloLoader = true;
    try {
      if (this.messageFromTextBox == null) {
        this.isEmailHasNoBody = true;
        this.inProcess = false;
        this.$store.state.wemloLoader = false;
        return;
      }
      let emailData = {
        message: this.messageFromTextBox,
        emailId: this.lenderEmailId,
        id: this.$route.query.id,
        brokerName:
          this.$store.state.sessionObject.userInfo.firstName +
          " " +
          this.$store.state.sessionObject.userInfo.lastName,
        subject: this.subject,
        type: this.type,
        lenderId: this.$route.query.lenderId,
        isLoanDetailsChecked: this.isLoanDetailsChecked
      };
      formData.append("data", JSON.stringify(emailData));

      let response = await Axios.post(
        BASE_API_URL + "broker/sendReplyToLender",
        formData);
      this.$modal.hide("viewMessageBox");

      this.messageFromTextBox = null;
      this.$snotify.success("Message Sent Sucessfully");
      // this.$modal.hide("replytoborrower");
      this.data.unshift(response.data.responseData);
      this.toggleReplyToBorrowerPopup();
      this.getBorrowerDetails();
      this.inProcess = false;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.inProcess = false;
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  /**************************************************************************************************
   *                                    IF DOCUMENT NOT LOADED THEN                                  *
   **************************************************************************************************/
  public ifDocumentNotLoad(time: number) {
    this.dotLoader = true;
    try {
      setTimeout(() => {
        let checkLoader = document.getElementById("checkLoader");
        if (checkLoader != null) {
          let iframe: any = document.getElementById("checkElement");
          if (iframe.localName == "iframe") {
            iframe.src =
              "https://docs.google.com/gview?url=" +
              this.fileToDisplay +
              "&embedded=true";
          } else {
            iframe.src = this.fileToDisplay;
          }
          this.ifDocumentNotLoad(time + 1000);
        }
      }, time);
    } catch (error) {
      console.log(error);
      this.dotLoader = false;
    }
  }

  mounted() {
    this.getBorrowerDetails();
  }
}
